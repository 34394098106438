/*@import "~antd/dist/antd.css";*/
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/*TO DISABLE BOUNCE ON MOBILE AND DESKTOP -> BUILD*/
html, body {
  /*overscroll-behavior: none;*/
  /*height: 100%;*/
  /*width: 100%;*/
  /*overflow: auto;*/
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

button {
  text-align: unset;
}

.leaflet-control-attribution {
  visibility: hidden;
}

@page  {
  size: landscape;
  margin: 1rem;
  padding: 0;
}


.ant-tooltip .ant-tooltip-inner {
  min-width: 90px;
  text-align: center;
  border-radius: 5px;
  background: #000000;
  -webkit-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 13px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pdp-modal .ant-tabs-nav-list {
  width: 100%;
}
.pdp-modal .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #f3887c;
  height: 4px;
}
.pdp-modal .ant-tabs-tab .ant-tabs-tab-btn {
  color: #3e5054;
  opacity: 0.4;
}
.pdp-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  opacity: 1;
  color: #f3887c;
}
.pdp-modal .ant-modal-content {
  min-height: 420px;
  border-radius: 10px;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.map-popup {
  padding: 8px 12px;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  pointer-events: none;
}
.map-popup .leaflet-popup-content {
  margin: 8px 12px;
  font-size: 13px;
  line-height: 13px;
}
.map-popup .leaflet-popup-tip,
.map-popup .leaflet-popup-content-wrapper {
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
}
.map-popup .leaflet-popup-content p {
  margin: 0;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout {
  height: 100%;
  width: 100%;
}

.ant-layout-content {
  height: 100%;
  width: 100%;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}
.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-draw-guide-dash {
  z-index: 10000;
}

.leaflet-popup-content p {
  user-select: none;
}

.adu-tooltip {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
  transform: rotate(-45deg);
}

.leaflet-draw-tooltip .leaflet-draw-tooltip-subtext {
  display: none;
}
.leaflet-draw-tooltip {
  background: #ffffff;
  border: 1px solid transparent;
  color: rgb(0, 0, 0);
  padding: 8px;
  display: flex;
  align-items: center;
  z-index: 6;
  border-radius: 5px;
  user-select: none;
  font-size: 13px;
  line-height: 13px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
}
.leaflet-draw-tooltip:before {
  border-right: 6px solid #fff;
  border-right-color: #fff;
}

.leaflet-measure-path-measurement {
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  user-select: none;
  text-shadow: -1px 0 0 rgb(0 0 0 / 50%), -1px -1px 0 rgb(0 0 0 / 45%),
    0 -1px 0 rgb(0 0 0 / 45%), 1px -1px 0 rgb(0 0 0 / 45%),
    1px 0 0 rgb(0 0 0 / 45%), 1px 1px 0 rgb(0 0 0 / 45%),
    0 1px 0 rgb(0 0 0 / 45%), -1px 1px 0 rgb(0 0 0 / 45%);
}
.leaflet-measure-path-measurement > div {
  position: relative;
  margin-top: -16%;
  left: -50%;
}

/* ALL MAPBOX STYLES */
.mapboxgl-popup-content {
  pointer-events: none;
  width: 100%;
  cursor: default;
  border-radius: 7px;
  box-shadow: 0 3px 4px rgba(73, 73, 73, 0.2);
  padding: 1rem 2rem;
  z-index: 0;
}
.mapboxgl-marker {
  cursor: pointer;
}
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-popup-content .h3  {
  width: 100%;
  font-size: 18px;
  color: #212121;
  text-align: left;
  font-family: Inter-Bold, sans-serif;

}
.mapboxgl-popup-content .h6  {
  font-size: 16px;
  color: #626262;
  text-align: left;
}
.mapboxgl-popup-content .enriched-subtitle  {
  font-size: 14px;
  color: #626262;
  text-align: left;

}
.mapboxgl-popup-content .enriched-title  {
  font-size: 18px;
  color: black;
  text-align: left;
  padding-bottom: 0rem;
}


.rsw-ce pre {
  /*word-break: break-word;*/
  white-space: pre-wrap;
}

.mapboxgl-popup-content .flex-row  {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

.mapboxgl-popup-content .enriched-text-container  {
  padding: 0.5rem 1rem;
  margin-bottom: 0rem;
}

.mapboxgl-popup-content .adu-image  {
  padding-left: -2rem;
  overflow: hidden;
  width: 100%;
}

.mapboxgl-popup-content .project-point-container  {
  margin: -1rem -2rem;
  margin-bottom: 0rem;
  overflow: hidden;
}


