/*#region NAV BARS */
:root {
  --success-color: #82c275;
  --accent-color: #82c275;
  --accent-color-active: #e6f3e3;
}

.formik-input {
  border: 1px solid #ccc;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  background: 0 0;
}

/*#endregion NAV BARS */

/*#region CAROUSELS */
.rendered-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .carousel {
    height: 100%;

    .carousel-indicators {
      margin-bottom: 0;
      bottom: 35px;

      li {
        background-color: #415053;
        margin-right: 5.5px;
        margin-left: 5.5px;
        border-bottom: none;
        border-top: none;
        border-radius: 30px;
      }
    }

    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
      z-index: 1000;
      top: 50%;
      bottom: 50%;

      .right-arrow {
        position: absolute;
        right: 52px;
      }

      .left-arrow {
        position: absolute;
        left: 52px;
      }
    }

    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;
        width: 100%;

        img {
          object-fit: cover;
          display: block;
        }
      }
    }
  }

  img {
    width: 100%;
  }
}

/*#endregion CAROUSELS */
.rendering-image-caption {
  position: absolute;
  bottom: 45px;
  margin: auto;
  text-align: center;
  max-width: 90%;
  width: fit-content;
  opacity: 0.7;
  transition: opacity ease-in 0.25s;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 20px;
}

/* #region BUTTONS */


/* #region GOOGLE SEARCH BAR */
.search-location-input {

  .input-icons-wrapper {
    position: relative;

    #address-input {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      outline: none;
      border: none;
      border-bottom: 1.5px solid rgba(0, 0, 0, 0.45);
    }

    img {
      position: absolute;
      top: 13px;
      right: 10px;
      z-index: 2;
    }
  }

  .error-message {
    margin-top: -15px;
  }
}

/* #endregion GOOGLE SEARCH BAR */

/* #region CONTACT FORM */


.error-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    color: #f96353;
  }
}

.error-input {
  border-bottom: 2px solid #f96353 !important;
}

/* #endregion CONTACT FORM */

/* #region MISC  */

.one-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 4px;
  }
}


/* The Modal (background) */
.fullDiv {
  position: absolute; /* Stay in place */
  padding: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}


.MuiPopover-root {
  z-index: 70000001 !important;
}

/* #endregion MISC  */
// react-select library override
.css-26l3qy-menu {
  z-index: 5000100 !important;
}


/* #endregion STEPPER COMPONENT */

/* #region STEP ZERO / GOOGLE SEARCH */

.search-location-input {

  .intake-input-icons-wrapper {

    margin-bottom: 10px;
    position: relative;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);

    #address-input {
      width: 100%;
      border: none;
      /*  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
       -webkit-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1); */
      height: 47px;
      outline: none;
      padding: 10px 35px 5px 35px;
      border-bottom: 2px solid transparent;

    }

    #next-arrow {
      position: absolute;
      top: 13px;
      right: 10px;
      z-index: 2;
      cursor: pointer;
    }

    #verified-icon {
      position: absolute;
      top: 14px;
      left: 10px;
      z-index: 2;
      border-radius: 30px;
    }


  }

  .error-message {
    margin-top: 10px !important;
  }


}


/* #endregion TOP BAR */


.hidden {
  display: none;
  transition: .25s all;
}


.error-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    color: #F96353;
    font-size: 16px;
  }
}

.error-input {
  border-bottom: 2px solid #F96353 !important;
}


.blur-image {
  position: absolute;
  filter: blur(20px);
  clip-path: inset(0);
  transition: opacity 0.2s;
  opacity: 1;
  left: 0;
  object-fit: cover;
  top: 0;
}

.blur-image-hide {
  opacity: 0;
}

.blur-image-transition {
  opacity: 0;
}

.image-fade {
  opacity: 0;
  transition: opacity 0.5s;
  object-fit: cover;
  z-index: 10;
}

.image-fade-loaded {
  opacity: 1;
}

/* #endregion MEDIA QUERIES */
